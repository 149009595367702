<template>
    <div>
        <div class="body" v-if="!is_login">
            <div class="prompt-container">
                <div class="prompt-inner">
                    <div class="title_container">
                        <h2>你正在访问 CU Campus</h2>
                    </div>
                    <p><span style="font-weight: bold;">CU Campus</span> 是香港中文大学的校园信息综合平台，你可以在此浏览课程评价，编排课程表，了解更多校园信息。
                    </p>
                    <p>继续访问即代表你同意
                        <span style="font-weight: bold; text-decoration: underline; cursor: pointer;"
                            @click="nav2Agreement">《CU Campus 用户服务协议》</span>
                        和
                        <span style="font-weight: bold; text-decoration: underline; cursor: pointer;"
                            @click="nav2Privacy">《CU Campus 隐私政策》</span>
                        ，并授权 Triple Uni 向 CU Campus 提供你的教育邮箱信息，用于创建 CU Campus 账户或关联已有账户。
                    </p>
                    <div class="prompt-button-group">
                        <div class="prompt-button-sub-vice" @click="navBack">返回</div>
                        <div class="prompt-button-sub-main" @click="visit">继续访问</div>
                    </div>
                </div>

            </div>
        </div>
        <iframe :src="iframe_url" id="iframe"></iframe>
    </div>
</template>

<script>
import Navbar from './Navbar.vue'

export default {
    name: 'UniMiddleCUCampus',
    components: { Navbar },
    created() {
        const user_school_label = localStorage.getItem('user_school_label')
        if (user_school_label != 'CUHK') this.$router.push('/home')
        const user_is_org = localStorage.getItem('user_is_org')
        if (user_is_org) this.$router.push('/home')
    },
    data() {
        return {
            is_login: localStorage.getItem('cu_campus_is_login'),
            iframe_url: 'https://cu-courses.vercel.app/'
        }
    },
    methods: {
        visit() {
            const user_school_label = localStorage.getItem('user_school_label')
            if (user_school_label != 'CUHK') {
                this.$modal.show({
                    title: '提示',
                    content: 'CU Campus 仅支持 CUHK 用户',
                    show_cancel: false,
                    success: () => {
                        this.$router.push('/home')
                    }
                })
                return
            }
            const user_is_org = localStorage.getItem('user_is_org')
            if (user_is_org) {
                this.$modal.show({
                    title: '提示',
                    content: 'CU Campus 不支持社团账号',
                    show_cancel: false,
                    success: () => {
                        this.$router.push('/home')
                    }
                })
                return
            }
            this.is_login = true
            // localStorage.setItem('cu_campus_is_login', true)
            document.getElementById('iframe').contentWindow.postMessage(localStorage.getItem('token'), 'https://cu-courses.vercel.app')
        },
        navBack() {
            if (window.history.length <= 1) {
                this.$router.push('/home')
            } else {
                this.$router.back()
            }
        },
        nav2Agreement() {
            window.open('https://terms.tripleuni.com/CUCampus/agreement')
        },
        nav2Privacy() {
            window.open('https://terms.tripleuni.com/CUCampus/privacy')
        }
    }
}
</script>

<style scoped>
iframe {
    width: 100%;
    height: 100vh;
    height: calc(100vh - env(safe-area-inset-bottom));
    border: none;
    margin: 0;
    display: flex;
}

.body {
    position: absolute;
    z-index: 100;
    background: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(5px);
    color: var(--text);
    margin: 0;
    font-family: "Chirp", sans-serif;
    overflow-x: hidden;
    display: flex;
    height: 100vh;
    height: calc(100vh - env(safe-area-inset-bottom));
    width: 100%;
    align-items: center;
}

.prompt-container {
    display: block;
    padding: 1rem;
    background-color: var(--bg);
    box-shadow: 0px 0px 10px var(--bg-grey);
    border-radius: 1rem;
    width: 80%;
    max-width: 30rem;
    margin: 1rem auto;
}

.title_container {
    width: 100%;
    border-bottom: 0.1rem solid var(--border-grey);
}

.prompt-container p {
    color: var(--text);
    font-size: 1rem;
    line-height: 1.5rem;
}

.prompt-button-group {
    margin-top: 30px;
    display: flex;
}

.prompt-button-sub-vice {
    flex: 1;
    padding: 15px 0;
    margin: 0 15px;
    margin-left: 0;
    border-radius: 25px;
    background-color: var(--border-grey);
    font-size: 16px;
    line-height: 20px;
    color: var(--text);
    font-weight: bold;
    cursor: pointer;
    text-align: center;
}

.prompt-button-sub-vice:hover {
    background-color: var(--bg-grey);
}

.prompt-button-sub-main {
    flex: 1;
    padding: 15px 0;
    margin: 0 15px;
    margin-right: 0;
    border-radius: 25px;
    background-color: var(--main);
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
    font-weight: bold;
    cursor: pointer;
    text-align: center;
}

.prompt-button-sub-main:hover {
    background-color: var(--main-darker);
}

.prompt-button {
    margin: 20px;
    padding: 15px 0;
    border-radius: 25px;
    background-color: var(--main);
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
    font-weight: bold;
    cursor: pointer;
    text-align: center;
}

.prompt-button:hover {
    background-color: var(--main-darker);
}

@media only screen and (max-width: 700px) {
    iframe {
        height: calc(100vh - 55px);
        height: calc(100vh - 55px - env(safe-area-inset-bottom));
    }

    .body {
        height: calc(100vh - 55px);
        height: calc(100vh - 55px - env(safe-area-inset-bottom));
    }

    .prompt-container {
        width: calc(100vw - 3rem);
        height: calc(100vh - 3rem);
        height: calc(100vh - 3rem - env(safe-area-inset-bottom));
        margin: 0;
        border: none;
        padding: 1.5rem;
        border-radius: 0;
        max-width: none;
        align-items: center;
        display: flex;
    }

    .prompt-inner {
        display: block;
        margin: 0 auto;
        width: 100%;
        max-width: 450px;
        padding-bottom: 100px;
    }
}
</style>